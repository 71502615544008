import axios from 'axios';
import { KJUR } from 'jsrsasign';
// var CryptoJS = require("crypto-js");
// import axios from 'axios';
import CryptoJS from 'crypto-js';

// export function callingAPI(path,postData) {
//     const apiUrl = process.env.REACT_APP_API_URI+path
//     return axios.post(apiUrl, postData)
//     .then((data) => data)
//     .then((res) => res)
//     .catch((err) => console.log(err, 'err'));

// }
// export function callingAPI_GET(path, postData) {
//     const apiUrl = process.env.REACT_APP_API_URI + path
//     return axios.get(apiUrl, postData)
//         .then((data) => data)
//         .then((res) => res)
//         .catch((err) => console.log(err, 'err'));
// }
// export function callingGetAPI(path) {
//     const apiUrl = process.env.REACT_APP_API_URI + path
//     return axios.get(apiUrl)
//         .then((data) => data)
//         .then((res) => res)
//         .catch((err) => console.log(err, 'err'));
// }
// export function callingPostAPI(path) {
//     const apiUrl = process.env.REACT_APP_API_URI + path
//     return axios.post(apiUrl)
//         .then((data) => data)
//         .then((res) => res)
//         .catch((err) => console.log(err, 'err'));
// }
// export function callingPutAPI(path, postData) {
//     const apiUrl = process.env.REACT_APP_API_URI + path
//     return axios.put(apiUrl, postData)
//         .then((data) => data)
//         .then((res) => res)
//         .catch((err) => console.log(err, 'err'));
// }
// export function callingDeleteAPI(path, postData) {
//     const apiUrl = process.env.REACT_APP_API_URI + path
//     return axios.delete(apiUrl, postData)
//         .then((data) => data)
//         .then((res) => res)
//         .catch((err) => console.log(err, 'err'));
// }

/****************************Token based Implementation********************* */


// export function callingAPI(path, postData) {
//     /// console.log("path....******...",path)
//     var tokenData;
//     const ENC_KEY = process.env.REACT_APP_ENCRYPTION_KEY // 32 DIGITS
//     console.log("callingAPI....****** post data...", postData)
//     if (!postData == "") {
//         var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(postData), process.env.REACT_APP_ENCRYPTION_KEY).toString();
//         tokenData = {
//             "token": ciphertext
//         }
//     }
//     else {
//         tokenData = ""
//     }
//     console.log("callingAPI token....******...", tokenData)
//     const apiUrl = process.env.REACT_APP_API_URI + path
//     // console.log("apiUrl....******...",apiUrl)
//     return axios.post(apiUrl, tokenData)
//         .then(data => {
//             console.log("tokensuccess....******...", data.data.token)
//             let obj = {}
//             var bytes = CryptoJS.AES.decrypt(data.data.token, ENC_KEY);
//             var decryptedData = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
//             console.log("ApiDataD....******...", path, decryptedData)
//             obj = {
//                 data: decryptedData
//             }
//             console.log("obj....******...", obj)
//             return obj;

//         })
//         // .then((data) => data)
//         .then((res) => res)
//         .catch((err) => console.log(err, 'err'));
// }
// export function callingAPI_GET(path, postData) {

//     var tokenData;
//     const ENC_KEY = process.env.REACT_APP_ENCRYPTION_KEY
//     console.log("ApiData", path, postData)
//     if (!postData == "") {
//         var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(postData), process.env.REACT_APP_ENCRYPTION_KEY).toString();
//         tokenData = {
//             "token": ciphertext
//         }
//     }
//     else {
//         tokenData = ""
//     }

//     const apiUrl = process.env.REACT_APP_API_URI + path
//     return axios.get(apiUrl, tokenData)
//         .then(data => {
//             let obj = {}
//             var bytes = CryptoJS.AES.decrypt(data.data.token, ENC_KEY);
//             var decryptedData = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
//             console.log("ApiDataD", path, decryptedData)
//             obj = {
//                 data: decryptedData
//             }
//             return obj;
//         })
//         // .then((data) => data)
//         .then((res) => res)
//         .catch((err) => console.log(err, 'err'));
// }
// export function callingGetAPI(path) {

//     var tokenData = "";
//     const ENC_KEY = process.env.REACT_APP_ENCRYPTION_KEY

//     const apiUrl = process.env.REACT_APP_API_URI + path
//     return axios.get(apiUrl)
//         .then(data => {
//             let obj = {}
//             var bytes = CryptoJS.AES.decrypt(data.data.token, ENC_KEY);
//             var decryptedData = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
//             console.log("ApiDataD", path, decryptedData)
//             obj = {
//                 data: decryptedData
//             }
//             return obj;
//         })
//         .then((res) => res)
//         .catch((err) => console.log(err, 'err'));
// }
// export function callingPostAPI(path) {

//     var tokenData = "";
//     const ENC_KEY = process.env.REACT_APP_ENCRYPTION_KEY
//     const apiUrl = process.env.REACT_APP_API_URI + path
//     return axios.post(apiUrl)
//         .then(data => {
//             let obj = {}
//             var bytes = CryptoJS.AES.decrypt(data.data.token, ENC_KEY);
//             var decryptedData = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
//             console.log("ApiDataD", path, decryptedData)
//             obj = {
//                 data: decryptedData
//             }
//             return obj;
//         })
//         .then((res) => res)
//         .catch((err) => console.log(err, 'err'));
// }
// export function callingPutAPI(path, postData) {

//     var tokenData;
//     const ENC_KEY = process.env.REACT_APP_ENCRYPTION_KEY
//     console.log("ApiData", path, postData)
//     if (!postData == "") {
//         var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(postData), process.env.REACT_APP_ENCRYPTION_KEY).toString();
//         tokenData = {
//             "token": ciphertext
//         }
//     }
//     else {
//         tokenData = ""
//     }
//     const apiUrl = process.env.REACT_APP_API_URI + path
//     return axios.put(apiUrl, tokenData)
//         .then(data => {
//             let obj = {}
//             var bytes = CryptoJS.AES.decrypt(data.data.token, ENC_KEY);
//             var decryptedData = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
//             console.log("ApiDataD", path, decryptedData)
//             obj = {
//                 data: decryptedData
//             }
//             return obj;
//         })
//         // .then((data) => data)
//         .then((res) => res)
//         .catch((err) => console.log(err, 'err'));
// }
// export function callingDeleteAPI(path, postData) {

//     var tokenData;
//     const ENC_KEY = process.env.REACT_APP_ENCRYPTION_KEY
//     console.log("ApiData", path, postData)
//     if (!postData == "") {
//         var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(postData), process.env.REACT_APP_ENCRYPTION_KEY).toString();
//         tokenData = {
//             "token": ciphertext
//         }
//     }
//     else {
//         tokenData = ""
//     }
//     const apiUrl = process.env.REACT_APP_API_URI + path
//     return axios.delete(apiUrl, tokenData)
//         .then(data => {
//             let obj = {}
//             var bytes = CryptoJS.AES.decrypt(data.data.token, ENC_KEY);
//             var decryptedData = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
//             console.log("ApiDataD", path, decryptedData)
//             obj = {
//                 data: decryptedData
//             }
//             return obj;
//         })
//         // .then((data) => data)
//         .then((res) => res)
//         .catch((err) => console.log(err, 'err'));
// }

/////////////////////// input normal and output token //////////////////////



// export function callingAPI(path, postData) {
//     const ENC_KEY = process.env.REACT_APP_ENCRYPTION_KEY;

//     const apiUrl = process.env.REACT_APP_API_URI + path;
//     return axios.post(apiUrl, postData)
//     .then(data => {
//         let obj = {}
//         var bytes = CryptoJS.AES.decrypt(data.data.token, ENC_KEY);
//         var decryptedData = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
//         obj = {
//             data:decryptedData
//         }
//         return obj;
//     })
//         .catch((err) => {
//             console.log(err, 'err');
//             throw err; // Rethrow the error to be caught by the outer catch block
//         });
// }


// export function callingAPI_GET(path, postData) {
//     const ENC_KEY = process.env.REACT_APP_ENCRYPTION_KEY

//     const apiUrl = process.env.REACT_APP_API_URI + path
//     return axios.get(apiUrl, postData)
//     .then(data => {
//         let obj = {}
//         var bytes = CryptoJS.AES.decrypt(data.data.token, ENC_KEY);
//         var decryptedData = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
//         obj = {
//             data:decryptedData
//         }
//         return obj;
//     })
//     .catch((err) => {
//         console.log(err, 'err');
//         throw err; // Rethrow the error to be caught by the outer catch block
//     });
// }
// export function callingGetAPI(path) {
//     const ENC_KEY = process.env.REACT_APP_ENCRYPTION_KEY

//     const apiUrl = process.env.REACT_APP_API_URI + path
//     return axios.get(apiUrl)
//     .then(data => {
//         let obj = {}
//         var bytes = CryptoJS.AES.decrypt(data.data.token, ENC_KEY);
//         var decryptedData = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
//         obj = {
//             data:decryptedData
//         }
//         return obj;
//     })
//     .catch((err) => {
//         console.log(err, 'err');
//         throw err; // Rethrow the error to be caught by the outer catch block
//     });
// }
// export function callingPostAPI(path) {
//     const ENC_KEY = process.env.REACT_APP_ENCRYPTION_KEY

//     const apiUrl = process.env.REACT_APP_API_URI + path
//     return axios.post(apiUrl)
//     .then(data => {
//         let obj = {}
//         var bytes = CryptoJS.AES.decrypt(data.data.token, ENC_KEY);
//         var decryptedData = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
//         obj = {
//             data:decryptedData
//         }
//         return obj;
//     })
//     .catch((err) => {
//         console.log(err, 'err');
//         throw err; // Rethrow the error to be caught by the outer catch block
//     });
// }
// export function callingPutAPI(path, postData) {
//     const ENC_KEY = process.env.REACT_APP_ENCRYPTION_KEY

//     const apiUrl = process.env.REACT_APP_API_URI + path
//     return axios.put(apiUrl, postData)
//     .then(data => {
//         let obj = {}
//         var bytes = CryptoJS.AES.decrypt(data.data.token, ENC_KEY);
//         var decryptedData = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
//         obj = {
//             data:decryptedData
//         }
//         return obj;
//     })
//         .catch((err) => {
//             console.log(err, 'err');
//             throw err; // Rethrow the error to be caught by the outer catch block
//         });
// }
// export function callingDeleteAPI(path, postData) {
//     const ENC_KEY = process.env.REACT_APP_ENCRYPTION_KEY
//     const apiUrl = process.env.REACT_APP_API_URI + path
//     return axios.delete(apiUrl, postData)
//     .then(data => {
//         let obj = {}
//         var bytes = CryptoJS.AES.decrypt(data.data.token, ENC_KEY);
//         var decryptedData = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
//         obj = {
//             data:decryptedData
//         }
//         return obj;
//     })
//     .catch((err) => {
//         console.log(err, 'err');
//         throw err; // Rethrow the error to be caught by the outer catch block
//     })
// }

////////////////////////// INPUT PARAMS AND OUTPUT TOKEN(CURRENTLY USING IN PULSE)

export function callingAPI(path, postData) {
    const ENC_KEY = process.env.REACT_APP_ENCRYPTION_KEY

    const apiUrl = process.env.REACT_APP_API_URI + path
    return axios.post(apiUrl, postData)
        .then(data => {
            console.log("tokensuccess....******...", data)
            let obj = {}
            var bytes = CryptoJS.AES.decrypt(data.data.token, ENC_KEY);
            var decryptedData = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
            console.log("ApiDataD....******...", path, decryptedData)
            obj = {
                data: decryptedData
            }
            console.log("obj....******...", obj)
            return obj;

        })
        // .then((data) => data)
        .then((res) => res)
        .catch((err) => console.log(err, 'err'));
}
export function callingAPI_GET(path, postData) {
    const ENC_KEY = process.env.REACT_APP_ENCRYPTION_KEY

    const apiUrl = process.env.REACT_APP_API_URI + path
    return axios.get(apiUrl, postData)
        .then(data => {
            let obj = {}
            var bytes = CryptoJS.AES.decrypt(data.data.token, ENC_KEY);
            var decryptedData = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
            console.log("ApiDataD", path, decryptedData)
            obj = {
                data: decryptedData
            }
            return obj;
        })
        // .then((data) => data)
        .then((res) => res)
        .catch((err) => console.log(err, 'err'));
}
export function callingGetAPI(path) {
    const ENC_KEY = process.env.REACT_APP_ENCRYPTION_KEY

    const apiUrl = process.env.REACT_APP_API_URI + path
    return axios.get(apiUrl)
        .then(data => {
            let obj = {}
            var bytes = CryptoJS.AES.decrypt(data.data.token, ENC_KEY);
            var decryptedData = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
            console.log("ApiDataD", path, decryptedData)
            obj = {
                data: decryptedData
            }
            return obj;
        })
        .then((res) => res)
        .catch((err) => console.log(err, 'err'));
}
export function callingPostAPI(path) {
    const ENC_KEY = process.env.REACT_APP_ENCRYPTION_KEY

    const apiUrl = process.env.REACT_APP_API_URI + path
    return axios.post(apiUrl)
        .then(data => {
            let obj = {}
            var bytes = CryptoJS.AES.decrypt(data.data.token, ENC_KEY);
            var decryptedData = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
            console.log("ApiDataD", path, decryptedData)
            obj = {
                data: decryptedData
            }
            return obj;
        })
        .then((res) => res)
        .catch((err) => console.log(err, 'err'));
}
export function callingPutAPI(path, postData) {
    const ENC_KEY = process.env.REACT_APP_ENCRYPTION_KEY

    const apiUrl = process.env.REACT_APP_API_URI + path
    return axios.put(apiUrl, postData)
        .then(data => {
            let obj = {}
            var bytes = CryptoJS.AES.decrypt(data.data.token, ENC_KEY);
            var decryptedData = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
            console.log("ApiDataD", path, decryptedData)
            obj = {
                data: decryptedData
            }
            return obj;
        })
        // .then((data) => data)
        .then((res) => res)
        .catch((err) => console.log(err, 'err'));
}
export function callingDeleteAPI(path, postData) {
    const ENC_KEY = process.env.REACT_APP_ENCRYPTION_KEY
    const apiUrl = process.env.REACT_APP_API_URI + path
    return axios.delete(apiUrl, postData)
        .then(data => {
            let obj = {}
            var bytes = CryptoJS.AES.decrypt(data.data.token, ENC_KEY);
            var decryptedData = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
            console.log("ApiDataD", path, decryptedData)
            obj = {
                data: decryptedData
            }
            return obj;
        })
        // .then((data) => data)
        .then((res) => res)
        .catch((err) => console.log(err, 'err'));
}
export function uploadImage(image, s3BucketPath) {
    return axios.post(`https://75vfqs60v3.execute-api.ap-south-1.amazonaws.com/newUpload?bucket=${process.env.REACT_APP_S3_BUCKET_NAME}&bucketPath=${process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE + s3BucketPath}`, image
    )
        .then((data) => data)
        .then((res) => res);
}